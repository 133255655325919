<template>
  <v-card>
    <v-card-text>
      <v-btn
        class="float-right"
        to="/fkadmin"
      >
        Admin Dashboard
      </v-btn>
      <h2>Bots</h2>
      <v-btn
        class="float-right"
        @click="createBot()"
      >
        Create Bot
      </v-btn>
      <v-btn
        class="float-right"
        @click="addRandomPointsToBot()"
      >
        Add Random Bot Points
      </v-btn>

      <v-btn
        class="float-right"
        @click="resetBot()"
      >
        Reset Bot Points
      </v-btn>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="users"
      item-key="uid"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.uuid="{ item }">
        {{ item.name }}<br>
        <small>({{ item.uid }})</small>
      </template>

      <template v-slot:item.silverKombatCoins="{ item }">
        {{ item.silverKombatCoins|toCurrencyNoDec }}<br>
      </template>
      <template v-slot:item.modified="{ item }">
        <span v-if="item.modified">
          {{ item.modified.seconds|moment("dddd MMM Do") }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'
import dbFunctions from '@/db'
import nameOptions from '@/data/nameGenerator.json'

export default {
  components: {},
  data() {
    return {
      search: '',
      users: [],
      names: nameOptions,
      logos: '',
      team: {
        name: '',
        logo: '',
      },
    }
  },
  computed: {
    headers() {
      return [
        {text: 'UUID', value: 'id'},
        {text: 'Name', value: 'name'},
        {text: 'Overall Rank', value: 'rank'},
        {text: 'Last Event Rank', value: 'lastPointsRank'},
        {text: 'Total Points', value: 'totalPoints'},
        {text: 'Last Points', value: 'lastPoints'},
      ]
    },
  },
  mounted() {
    this.getBots()
  },
  methods: {
    getLogos() {
      this.loadLogos(require.context('@/assets/images/team-logos/', true, /\.jpg$/))
    },
    loadLogos(r) {
      const logos = []
      r.keys().forEach(key => (logos.push(r(key))))
      this.logos = this.shuffleLogos(logos)
      this.randomTeam()
    },
    shuffleLogos(logoList) {
      const array = logoList
      array.sort(() => Math.random() - 0.5) // rough shuffle not truly random

      return array
    },
    async randomTeam() {
      function randomWord(arrayInput) {
        const word = arrayInput[Math.floor(Math.random() * arrayInput.length)]

        return word.charAt(0).toUpperCase() + word.slice(1)
      }

      this.team.name = `${randomWord(this.names.adjectives)} ${randomWord(this.names.nouns)}`
      const logo = this.logos[Math.floor(Math.random() * this.logos.length)]
      this.team.logo = logo
    },
    async createBot() {
      this.getLogos()
      await this.randomTeam()
      const ownerid = Math.random().toString(36).slice(2, 20) + Math.random().toString(36).slice(2, 20)
      const lastPoints = Math.floor(Math.random() * 25) + 11
      const advisors = [
        {
          name: 'Anderson Silva',
          mainImage: 'https://firebasestorage.googleapis.com/v0/b/fantasykombat.appspot.com/o/advisors%2F1633790003249-toon-asilva.png?alt=media&token=0819a970-2944-494f-998b-ffe48a12ac22',
        },
        {
          name: 'Michael Bisping',
          mainImage: 'https://firebasestorage.googleapis.com/v0/b/fantasykombat.appspot.com/o/advisors%2F1633802737375-toon-bisping.png?alt=media&token=45f8683a-76c3-4b86-8fb8-25fdfa12d45a',
        },
        {
          name: 'Ronda Rousey',
          mainImage: 'https://firebasestorage.googleapis.com/v0/b/fantasykombat.appspot.com/o/advisors%2F1633802222793-toon-rrousey.png?alt=media&token=6f17616c-3536-489b-94db-272f3ebffa74',
        },
      ]
      const advisor = advisors[Math.floor(Math.random() * advisors.length)]
      const template1 = {
        privacy: 'private',
        name: this.team.name,
        leagues: [
          '4dcsBRhlAaWf9GcBZDIO', 'Abxzk18TO90JtWkatF0v',
        ],
        logo: this.team.logo,
        advisor,
        type: 'bot',
        created: firebase.firestore.FieldValue.serverTimestamp(),
        captain: {
          name: '',
        },
        lastPoints,
        owner: ownerid,
        id: ownerid,
      }
      console.log(template1)
      await dbFunctions.createTeam(ownerid).then(() => {
        console.log(ownerid)
        dbFunctions.updateTeam(ownerid, template1)
      })
    },
    async resetBot() {
      dbFunctions.getLeagueTeams('4dcsBRhlAaWf9GcBZDIO').then(querySnapshot => {
        this.teams = []
        querySnapshot.forEach(data => {
          if (data.type === 'bot') {
            const fields = {
              lastTotal: data?.totalPoints + data?.lastPoints,
              lastPowerUpTotal: data?.lastPowerUpTotal || 0,
              lastCombined: 0,
              lastPowerUpPoints: 0,
              lastPoints: 0,
            }
            console.log(fields)
            dbFunctions.updateTeam(data.owner, fields)
          }
        })
      })
    },
    async addRandomPointsToBot() {
      dbFunctions.getLeagueTeams('4dcsBRhlAaWf9GcBZDIO').then(querySnapshot => {
        this.teams = []
        querySnapshot.forEach(data => {
          if (data.type === 'bot') {
            const fields = {
              totalPoints: data?.totalPoints + data?.lastPoints,
              lastPowerUpTotal: data?.lastPowerUpTotal || 0,
              lastCombined: 0,
              lastPowerUpPoints: 0,
              lastPoints: Math.floor(Math.random() * 20) + 11,
            }
            console.log(fields)
            dbFunctions.updateTeam(data.owner, fields)
          }
        })
      })
    },
    getBots() {
      dbFunctions.getLeagueTeams('4dcsBRhlAaWf9GcBZDIO').then(querySnapshot => {
        this.teams = []
        querySnapshot.forEach(data => {
          if (data.type === 'bot') {
            this.users.push(data)
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
</style>
